<template>
  <div class="article-grid mt-4 mb-5" id="articleGrid">
    <div class="container">

      <header
          class="article-grid__title mb-3"
          v-if="hideArticleGridHeader"
          @enter="showElement"
          @leave="hideElement"
      >
        <h2 class="text-white m-0">Voorbeelden</h2>
      </header>

      <div class="mb-4">

        <transition-group
            name="articles"
            class="row"
            @enter="enterElement"
            @leave="leaveElement"
            v-bind:css="false"
            tag="div"
            mode="out-in"
        >

          <div
              v-for="(article, index) in filteredArticles"
              :class="[ article.overrideColClass
                ? article.overrideColClass
                : filteredArticles.length > 2
                    ? 'col-md-12 col-lg-4'
                    : 'col-md-12 col-lg-6']"
              :key="`article_` + article.id"
          >
            <div :class="`article-card article-card--` + article.id + ` mb-3 mb-md-5 mb-lg-0`">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-12">
                  <figure
                      class="article-card__figure mb-3 mb-sm-3 mb-lg-4"
                      role="button"
                      aria-expanded="false"
                      data-bs-toggle="collapse"
                      v-bind:data-bs-target="`#cardCollapse` + index"
                      v-bind:aria-controls="`cardCollapse` +  index"
                      v-if="article.image"
                  >
                    <img :src="article.image" :title="article.title" :alt="article.title" width="636" class="img-fluid img-match-height">
                    <figcaption class="article-card__figure-caption d-md-none">
                      <div>
                        <h3 class="h3 text-white m-0">{{ article.title }}</h3>
                        <p class="m-0 text-white" v-if="article.subtitle">{{ article.subtitle }}</p>
                      </div>
                      <i class="material-icons" aria-hidden="true">chevron_right</i>
                    </figcaption>
                  </figure>

                </div>
                <div class="col-12 col-md-6 col-lg-12">

                  <header class="article-card__header mb-2 d-md-block" v-if="article.title" v-bind:class="{ 'd-none' : article.preventCollapse != true}">
                    <h3 class="h3 text-white m-0" v-if="article.title">{{ article.title }}</h3>
                    <p class="m-0 text-white" v-if="article.subtitle">{{ article.subtitle }}</p>
                  </header>

                  <div class="article-card__body">

                    <div class="d-md-block" v-bind:class="{ 'collapse' : article.preventCollapse != true}" v-bind:id="`cardCollapse` + index">

                      <p class="m-0" v-html="article.description" />

                      <p class="share-link mt-3 mb-0" v-if="article.showShareLink">
                          <a :href="getLinkedinShareLink" target="_blank">
                              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path></svg>
                              <span>Delen op LinkedIn</span>
                          </a>
                      </p>

                      <div v-if="article.callToAction" class="mt-3">
                        <button v-if="article.callToAction.embedUrl"
                            type="button"
                            class="btn btn-sm btn-outline-light"
                            data-bs-toggle="modal"
                            data-bs-target="#videoModal"
                            @click.prevent="openModalWindow(article.callToAction.embedUrl)"
                        >
                          <span>{{ article.callToAction.label }}</span>
                          <i class="material-icons" aria-hidden="true">play_circle_outline</i>
                        </button>
                        <button v-if="article.callToAction.url"
                            type="button"
                            class="btn btn-sm btn-outline-light"
                            @click.prevent="openNewWindow(article.callToAction.url)"
                            onClick='window.open(url: "article.callToAction.url")'
                        >
                            <span>{{ article.callToAction.label }}</span>
                            <i class="material-icons align-text-center" aria-hidden="true" style="vertical-align: middle;">link</i>
                        </button>
                      </div>

                      <div class="mt-1 d-md-none">
                        <a
                            v-bind:href="`#cardCollapse` + index"
                            v-bind:aria-controls="`#cardCollapse` + index"
                            class="btn btn-sm text-reset ps-0"
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            v-if="article.preventCollapse != true"
                        >
                          <span>Inklappen</span>
                          <i class="material-icons" aria-hidden="true">expand_more</i>
                        </a>
                      </div>

                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

        </transition-group>

      </div>

    </div>

    <div class="modal fade" ref="videoModal" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn btn-reset" aria-label="Close" @click.prevent="closeModal()">
              <i class="material-icons" aria-hidden="true">close</i>
            </button>
          </div>
          <div class="modal-body bg-white p-0">
            <div class="ratio ratio-16x9">
              <iframe v-bind:src="modalEmbedUrl" title="Video" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import anime from 'animejs';
export default {
  props: ['articles'],
  data() {
    return {
      modal: null,
      showModal: false,
      modalEmbedUrl: null,
      selectedTrendID: 1,
      hideArticleGridHeader: true
    }
  },
  methods: {
    openModalWindow: function (url) {
      this.modalEmbedUrl = url;
      this.modal.show();
    },
    openNewWindow: function (url) {
      window.open(url);
    },
    closeModal: function () {
      this.modalEmbedUrl = '';
      this.modal.hide();
    },
    enterElement(el,done) {
      anime({
        targets: el,
        translateY: [400, 0],
        opacity: [0, 1],
        easing: 'easeInOutSine',
        complete: done,
        delay: 500,
      })
    },
    leaveElement(el,done) {
      anime({
        targets: el,
        translateY: [0, 400],
        opacity: [1, 0],
        easing: 'easeInOutSine',
        complete: done,
      })
    },
    showElement(el,done) {
      anime({
        targets: el,
        opacity: [0, 1],
        easing: 'easeInOutSine',
        complete: done,
        delay: 500,
      })
    },
    hideElement(el,done) {
      anime({
        targets: el,
        opacity: [1, 0],
        easing: 'easeInOutSine',
        complete: done,
      })
    }
  },
  computed: {
    getLinkedinShareLink() {
      return 'https://www.linkedin.com/sharing/share-offsite/?url=' + window.location.origin;
    },
    filteredArticles() {
      return this.articles.filter((el) => {
        return el.relatedTo.includes(this.selectedTrendID);
      })
    }
  },
  mounted() {
    this.modal = new Modal(this.$refs.videoModal);
    this.$root.$on('showRelatedArticles', data => {
      this.selectedTrendID = data;
    });
    this.$root.$on('hideArticleGridHeader', () => {
      this.hideArticleGridHeader= false;
    });
    this.$root.$on('showArticleGridHeader', () => {
      this.hideArticleGridHeader= true;
    });
  }
}
</script>
