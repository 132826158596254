import { createPopper } from '@popperjs/core';
import bootstrap from 'bootstrap';
$ = window.$ = window.jQuery = require('jquery');

import Vue from 'vue';

import VueAwesomeSwiper from 'vue-awesome-swiper';
// import VueMatchHeights from 'vue-match-heights';
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper /* { default options with global component } */)
// Vue.use(VueMatchHeights);

import articleSwiper from './vue/components/articleSwiper';
import articleGrid from './vue/components/articleGrid';

const components = {};

components.articleSwiper = () => import('./vue/components/articleSwiper.vue');
components.articleGrid = () => import('./vue/components/articleGrid.vue');

const app = new Vue({
    el: '#app',
    components,
});
