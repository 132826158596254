<template>
  <div class="card-trends-swiper">
    <div class="card-trends-swiper__inner container">

      <swiper
          ref="articleSwiper"
          :options="swiperOptions"
          @slideChange="filterArticles(cards[swiper.activeIndex].id, swiper.activeIndex)"
      >
        <swiper-slide
            v-for="(card, index) in cards"
            v-bind:key="index"
        >
          <div
              v-bind:key="index"
              v-on:click="filterArticles(card.id, index)"
              class="card-trend"
              role="button"
              tabindex="0"
          >
            <div class="card-trend__header mb-3">
              <span class="card-trend__header-count mb-3">
                  <span v-if="index + 1 != cards.length">{{ index + 1 }}/{{ cards.length - 1 }}</span>
                  <span v-else>&nbsp;</span>
              </span>
              <div class="card-trend__header-headline mb-3">
                <h2 class="h2 text-white m-0">{{ card.title }}</h2>
                <figure class="mb-0 ms-2">
                  <img :src="card.icon" :title="card.title" :alt="card.title" class="img-fluid" width="65">
                </figure>
              </div>
            </div>
            <div class="card-trend__body">
              {{ card.description }}
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <div class="swiper-navigation mt-4 mb-2">
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-controls ms-3 d-none d-md-flex">
          <button class="swiper-controls-prev"><i class="material-icons" aria-hidden="true">arrow_back</i><span
              class="visually-hidden">Vorige</span></button>
          <button class="swiper-controls-next ms-1"><i class="material-icons" aria-hidden="true">arrow_forward</i><span
              class="visually-hidden">Vorige</span></button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    cards: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      swiperOptions: {
        watchOverflow: true,
        navigation: {
          nextEl: '.swiper-controls-next',
          prevEl: '.swiper-controls-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        slideToClickedSlide: true,
        initialSlide: 0,
        slidesPerView: 4,
        spaceBetween: 20,
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          540: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
        on: {
          snapGridLengthChange: function () {
            if (this.snapGrid.length != this.slidesGrid.length) {
              this.snapGrid = this.slidesGrid.slice(0);
            }
          },
          slideChange: () => {
            let swiper = this.$refs.articleSwiper.$swiper;
            let nbrOfSlides = swiper.slides.length;
            let index = swiper.activeIndex + 1;
            if(nbrOfSlides == index) {
              this.hideArticleGridHeader()
            } else {
              this.showArticleGridHeader()
            }
          }
        }
      }
    }
  },
  methods: {
    filterArticles: function (id, index) {
      this.activeCard = index;
      this.$root.$emit('showRelatedArticles', id);
    },
    hideArticleGridHeader: function () {
      this.$root.$emit('hideArticleGridHeader');
    },
    showArticleGridHeader: function () {
      this.$root.$emit('showArticleGridHeader');
    },
  },
  computed: {
    swiper() {
      return this.$refs.articleSwiper.$swiper
    }
  },
}
</script>
